import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form']

  result (data) {
    const result = data.detail[0];

    if(result.status === 'ok') {
      window.location.reload();
    } else {
      this.formTarget.innerHTML = result.form;
    }
  }
}
