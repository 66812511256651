import { Controller } from 'stimulus'
import ujs from '@rails/ujs'

export default class extends Controller {
  static targets = ['traditional', 'neutral', 'toggl', 'container']

  connect () {
    this.show('traditional')
    this.setupLock()
  }

  disconnect () {
    clearInterval(this.lockInterval)
  }

  toggle (event) {
    this.traditionalTarget.classList.remove('single-lesson__path-button--active')
    this.neutralTarget.classList.remove('single-lesson__path-button--active')

    const mode = event.target.dataset.mode
    this[`${mode}Target`].classList.add('single-lesson__path-button--active')
    this.togglTarget.classList.toggle('reversed', mode !== 'traditional')
    this.show(mode)
  }

  show (mode) {
    this.containerTarget.querySelectorAll('.single-lesson__part--traditional, .single-lesson__part--neutral').
      forEach((elem) => {
        elem.style.display = 'none'
      })

    this.containerTarget.querySelectorAll(`.single-lesson__part--${mode}`).forEach((elem) => {
      elem.style.display = ''
    })
  }

  setupLock () {
    const id = this.containerTarget.dataset.id
    const lock = (this.containerTarget.dataset.lock === 'true')

    if (lock) {
      this.lockInterval = setInterval(() => {
        ujs.ajax({
          url: `/lessons/${id}/lock`,
          type: 'GET',
          dataType: 'json',
          success: (data) => {
            if (data.status !== 'ok') {
              window.location = '/'
            }
          },
        })
      }, 30000)
    }
  }
}
