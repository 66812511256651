import { Controller } from 'stimulus'
import ujs from '@rails/ujs'

export default class extends Controller {
  static targets = ['container']

  on() {
    this.toggle()
    ujs.handleMethod.call(this.event.target)
  }

  off() {
    this.toggle()
  }

  confirm(event) {
    this.event = event;
    ujs.stopEverything(event)
    this.toggle();
  }

  toggle () {
    this.containerTarget.classList.toggle('visible')
    document.body.classList.toggle('overflow-hidden')
  }
}
