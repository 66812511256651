import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['background', 'dropdown', 'selected', 'group']

  connect() {
    this.show('group_1')
  }

  toggle () {
    this.backgroundTarget.classList.toggle('visible')
    this.dropdownTarget.classList.toggle('visible')
  }

  select(event) {
    this.selectedTarget.getElementsByClassName('heading')[0].innerHTML = event.currentTarget.getElementsByClassName('heading')[0].innerHTML
    this.selectedTarget.getElementsByClassName('subheading')[0].innerHTML = event.currentTarget.getElementsByClassName('subheading')[0].innerHTML
    this.show(event.currentTarget.dataset.group)
    this.toggle()
  }

  show(group) {
    this.groupTargets.forEach((el) => el.classList.add('d-none'))
    this.groupTargets.find((el) => el.dataset.group === group).classList.remove('d-none');
  }
}
