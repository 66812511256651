import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['flash']

  connect () {
    setTimeout(() => {
      this.flashTarget.style.transform = 'translateX(15px)'
      this.flashTarget.style.opacity = 1

      setTimeout(() => {
        this.flashTarget.style.opacity = 0
        this.flashTarget.style.transform = 'translateX(-100%)'
        this.flashTarget.addEventListener('transitionend', () => this.flashTarget.remove())
      }, 5000)
    }, 100)
  }
}
